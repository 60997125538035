import React, { useEffect } from 'react';

import { Header, HEADER_TYPES, SEO } from '../components';
import { IAppContext } from '../context/app/app.types';
import { useAppContext } from '../hooks/context/appContext.hook';

const NotFoundPage = () => {
	const { setIsGridVisible }: IAppContext = useAppContext();

	useEffect(() => {
		setIsGridVisible(false);
	}, [setIsGridVisible]);

	return (
		<>
			<SEO />

			<div data-scroll-section>
				<Header type={HEADER_TYPES.Dark} />
			</div>

			<main className="u-stack u-layout">
				<section data-scroll-section>
					The requested page could not be found.
				</section>
			</main>

			<footer data-scroll-section></footer>
		</>
	);
};

export default NotFoundPage;
